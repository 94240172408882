import { FormControl, TextField } from "@mui/material";
import {  Controller } from "react-hook-form";
import { addErrorIntoField } from "../utils";
import ErrorMessage from "./ErrorMessage";

interface MuiTextFieldProps {
  label: string;
  inputProps?: any;
  control: any;
  name: string;
  errors: any;
  type: any;
  reset?: () => void; // Make the reset property optional
}

const MuiTextField: any = ({
  label,
  inputProps,
  control,
  name,
  errors,
  type,
  reset, // Receive the reset property
}: MuiTextFieldProps) => {
  return (
    <FormControl fullWidth sx={{ mb: "1rem" }}>
      <Controller
        name={name}
        control={control}
        render={({ field }: any) => (
          <TextField
            {...field}
            {...addErrorIntoField(errors[name])}
            required
            label={label}
            variant="outlined"
            InputProps={{ ...inputProps, type: type || "text" }}
            value={field.value} // Set the value prop to retain the field value
            onChange={(e) => {
              field.onChange(e); // Call the field onChange function
              if (reset) reset(); // Call the reset function if available
            }}
          />
        )}
      />
      {errors[name] ? <ErrorMessage message={errors[name].message} /> : null}
    </FormControl>
  );
};

export default MuiTextField;