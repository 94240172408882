export const getProjectSuccess = 'To project που αναζητήσατε ανακτήθηκε με επιτυχία.';
export const deleteProjectSuccess = "Το project διαγράφηκε.";

export const getProjectsSuccess = 'Tα project που αναζητήσατε ανακτήθηκαν με επιτυχία.';

export const getProjectWarning = 'To project που αναζητήσατε υπάρχει στον πίνακα ήδη';

export const getProjectError = 'Σφάλμα κατά την αναζήτηση του project.';
export const deleteProjectError = 'Σφάλμα κατά τη διαγραφή του project.';


export const searchProjectWarning = "Το project που αναζητήσατε δεν υπάρχει.";
export const searchProjectSuccess = "Το project που αναζητήσατε βρέθηκε.";
export const searchProjectError = "Σφάλμα κατά τη δισαδικασία εύρεσης του project.";



export const createProjectsSuccess ="To project δημιουργήθηκε επιτυχώς.";
export const createProjectError ="Σφάλμα κατά τη δημιουργία  του project.";

export const get3DModelError = 'Σφάλμα στο 3D μοντέλο.';

export const noAvaliablePixelsError = "Έχετε υπερβεί το όριο των διαθέσιμων pixel.";

export const zipExportDownloadButtonCreate = "ΔΗΜΙΟΥΡΓΗΣΕ ΕΝΑ ZIP ΓΙΑ ΚΑΤΕΒΑΣΜΑ";
export const zipExportDownloadButtonCreateError = "Η ΔΗΜΙΟΥΡΓΙΑ ΤΟΥ ZIP ΑΠΕΤΥΧΕ";
export const zipExportDownloadButtonDownload = "ΚΑΤΕΒΑΣΕ ΤΟ PROJECT ΣΕ ZIP";
export const zipExportDownloadButtonWait = "ΠΕΡΙΜΕΝΕ ΝΑ ΔΗΜΙΟΥΡΓΗΘΕΙ ΤΟ ZIP";

export const getUserPixelsError = "Σφάλμα κατά τον υπολογισμό του διαθέσιμου χώρου εικόνων.";


export const getCategorySuccess = 'Η κατηγορία που αναζητήσατε ανακτήθηκε με επιτυχία.';
export const getCategoryWarning = 'Η κατηγορία που αναζητήσατε υπάρχει στον πίνακα ήδη';
export const getCategoryError = 'Σφάλμα κατά την αναζήτηση των κατηγοριών.';
export const deleteCategoryError = 'Σφάλμα κατά τη διαγραφή της κατηγορίας.';

export const deleteUserSuccess = 'Οχρήστης διαγράφτηκε επιτυχώς.';
export const deleteUserError = 'Σφάλμα κατά τη διαγραφή του χρήστη.';

export const unauthorizedUserLogIn = 'Ο χρήστης με τα στοιχεία αυτά δεν έχει γίνει σποδεκτός η είναι ανενεργός.';

export const userIsAdminnMessage = 'Συμμετοχή';
export const userIsNotAdminMessage = 'Μη συμμετοχή';
export const userIsNotPermittedToBeAdminMessage = 'Δεν έχει δικαίωμα';
export const userIsActiveMessage = 'Ενεργός';
export const userIsNotActiveMessage = 'Ανενεργός';
export const userIsNotApprovedMessage = 'Μη επιβεβαιωμένος';

export const setUserAsAdminConfirmationMessage = 'Ανάθεση';
export const unsetUserAsAdminConfirmationMessage = 'Κατάργηση';
export const setUserActiveConfirmationMessage = 'Ενεργοποίηση';
export const setUserNotActiveConfirmationMessage = 'Απενεργοποίηση';
export const setUserApprovedConfirmationMessage = 'Προς επιβεβαίωση';

export const setUserAsAdminConfirmationModalMessage = 'Είστε σίγουρος/η ότι θέλετε να γίνει διαχειριστής αυτός ο χρήστης;';
export const unsetUserAsAdminConfirmationModalMessage = 'Είστε σίγουρος/η ότι θέλετε να καταργηθεί από διαχειριστής αυτός ο χρήστης;';
export const userIsNotPermittedToBeAdminModalMessage = 'Πρέπει πρώτα να αποδεχτείτε και να ενεργοποιήσετε τον χρήστη.';
export const setUserActiveConfirmationModalMessage = 'Είστε σίγουρος/η ότι θέλετε να ενεργοποιήσετε τον χρήστη;';
export const setUserNotActiveConfirmationModalMessage = 'Είστε σίγουρος/η ότι θέλετε να απενεργοποιήσετε τον χρήστη;';
export const setUserApprovedConfirmationModalMessage = 'Είστε σίγουρος/η ότι θέλετε να  γίνει αποδοχή αυτού του χρήστη;';
export const setUserNotApprovedConfirmationModalMessage = 'Είστε σίγουρος/η ότι θέλετε να καταργήσετε την αποδοχή τον χρήστη;';
export const changeUserCateogryModalMessage = 'Είστε σίγουρος/η ότι θέλετε ο χρήστης να μπει στην κατηγορία ';

export const updateUserAsAdminSuccessMessage = 'Τα στοιχεία του χρήστη ενημερώθηκαν με επιτυχία.';
export const updateUserAsAdminErrorMessage = 'Σφάλμα κατά την ενημέρωση των στοιχείων του χρήστη.';

export const setNewUserCtaegoryMenuTitle = 'Επιλέξτε την κατηγορία του νέου χρήστη: ';


