

import './design.css';

import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, Button } from '@mui/material';
import Iframe from 'react-iframe';


export const DataTable = ({ rows,tableColumns }: { rows: any,tableColumns: any }) => {
  return (
    <div style={{ width: '80%', overflow: 'auto', display: 'flex' }}>
      <DataGrid 
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }],
        },
      }}
      rows={rows} columns={tableColumns} sx={{alignItems:'flex'}}/>
    </div>
  );
};

export function DisplayErrorMessage({ message }: { message: string }) {
  return <div>{message}</div>;
}

export const displayTitleWithTypography = (name: any) => {
  return (
    <Typography align="center" variant="h4" gutterBottom>
        {name}
    </Typography>
  );
  
}

export const displayFieldWithTypography = (name: any, data: any, index: number) => {
  return (
    <Typography align="center" variant="subtitle1" key={index} >
       <strong>{name}</strong>{data}
    </Typography>
  );
  
}

export const display3dWithIframe = (url: any) => {
  return (

    <Iframe url={url}
      width="1500px"
      height="520px"
      className=""
      display="initial"
      position="relative"/>
  );
}

 